@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.page-content {
  p, figcaption, li{
    line-height: 2;
    @include tablet{
      font-size: $font-xs;
    }
  }
  p {
    margin-bottom: $font-s;
  }
}
.single-content{
  p, figcaption, li{
    line-height: 2;
    @include tablet{
      font-size: $font-xs;
    }
  }
  p{
    margin: $space-xs 0;
  }
  strong, h2, h3, h4{
    font-weight: bold;
  }
  h2{
    line-height: 1.5;
    font-size: $font-l;
    padding: $space-s 0 $space-xs 0;
    margin-bottom: $space-s;
    border-bottom: 5px solid $bg-gray;
    @include tablet{
      font-size: $font-m;
    }
  }
  h3{
    line-height: 1.5;
    font-size: $font-m;
    padding: $space-s 0 $space-xs 0;
    margin-bottom: $space-s;
    border-bottom: 2px solid $bg-gray;
    @include tablet{
      font-size: $font-s;
    }
  }
  h4{
    line-height: 1.5;
    margin: $space-m 0 $space-s;
  }
  code{
    font-family: Consolas, 'Courier New', Courier, Monaco, monospace;
    background: $bg-gray;
    border-radius: 5px;
    padding: 2px $space-xxs;
  }
  em{
    font-style: italic;
  }
  ul{
    list-style: inside;
    margin: $space-s 0;
    padding: $space-xs;
    background: $bg-gray;
    @include tablet{
      font-size: $font-xs;
    }
  }
  .wp-block-quote{
    margin: $space-s 0;
    border: 2px solid $bg-gray;
    padding: $space-xs;
  }
  .wp-block-image.size-large{
    img{
      height: auto;
      max-width: 750px;
      margin: 0 auto;
      display: block;
      @include tablet{
        max-width: 100%;
      }
    }
    figcaption{
      text-align: center;
    }
  }

}

.single-thumbnail img{
  height: auto;
  width: 100%;
  max-width: 750px;
  margin: 0 auto $space-m;
  display: block;
  @include tablet{
    max-width: 100%;
  }
}
