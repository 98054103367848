@use "stylesheets/foundation/variables" as *;

@mixin desktop {
  @media (max-width: ($desktop)){
    @content;
  }
}
@mixin laptop {
  @media (max-width: ($laptop)){
    @content;
  }
}
@mixin tablet {
  @media (max-width: ($tablet)){
    @content;
  }
}
@mixin smartphone {
  @media (max-width: ($smartphone)){
    @content;
  }
}