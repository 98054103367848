@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.breadcrumb{
  &__inner{
    width: $desktop;
    margin: 0 auto;
    padding: $space-s 0;
    @include smartphone{
      width: 100%;
      padding: $space-xs $space-s;
    }
  }
  ul{
    overflow: hidden;
    padding-right: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    li{
      display: inline;
      margin-right: $space-s;
      color: #666666;
      text-decoration: none;
      font-weight: bold;
      font-size: 14px;
      @include smartphone{
        margin-right: $space-xs;
        font-size: 12px;
      }
      a{
        color: #666666;
        text-decoration: none;
      }
      &:after{
        font-family: "Ionicons";
        content: "\f3d3";
        margin-left: $space-s;
        @include smartphone{
          margin-left: $space-xs;
        }
      }
      &:last-child{
        &:after{
          display: none;
        }
      }
    }
  }
}
