@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.section{
  padding: 80px 0;
  position: relative;
  @include smartphone{
    padding: $space-l 0;
  }
  &--colored{
    .heading{
      color: #fff ;
    }
    &:before{
      width: 100%;
      height: 200px;
      content: "";
      display: block;
      background: linear-gradient(to left, #008EEE, #004386);      
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }
}
