@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.header-lead{
  width: 100%;
  height: 200px;
  margin-top: 100px;
  background-image: url(./images/about/lead.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  position: relative;
  display: flex;
  align-items: center;
  @include smartphone{
    height: 150px;
    margin-top: 75px;
  }
}