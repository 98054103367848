@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.contact-page-text {
  line-height: 2;
}
.contact{
  width: 100%;
  padding: $space-m;
  text-align: center;
  background-image: url(./images/contact.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  position: relative;
  @include smartphone{
    padding: $space-s;
  }
  &__label{
    & > span{
      margin-bottom: $space-s;
      font-family: 'Lato', sans-serif;
      font-size: $font-m;
      font-weight: bold;
      letter-spacing: .1rem;
      display: block;
      @include smartphone{
        margin-bottom: $space-xs;
        text-align: left;
      }
    }
  }
  &__heading{
    margin-bottom: $space-xs;
    font-size: $font-m;
    font-weight: bold;
    line-height: 1.5;
    @include smartphone{
      font-size: $font-s;
      text-align: left;
    }
  }
  &__message{
    line-height: 1.5;
    @include smartphone{
      font-size: $font-xs;
      text-align: justify;
    }
  }
}