@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.container{
  width: $desktop;
  margin-left: auto;
  margin-right: auto;
  @include smartphone{
    width: 100%;
    padding-left: $space-s;
    padding-right: $space-s;
  }
 }

 .space-between{
   display: flex;
   justify-content: space-between;
 }