@use "stylesheets/foundation/variables" as *;

@each $name, $color in $bg-colors {
  .bg{
    &-#{$name} {
      background: $color;
    }
  }
}

@each $name, $color in $colors {
  .color{
    &-#{$name} {
      color: $color;
    }
  }
}