@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.form{
  margin-top: 40px;
  &-row{
    display: flex;
    margin-bottom: $space-s;
    align-items: center;
    @include smartphone{
      display: block;
    }
    &.align-top{
      align-items: flex-start;
    }
  }
  .label,.label-none{
    color: $color-black;
    display: block;
    width: 250px;
    margin-right: $space-s;
    position: relative;
    font-weight: bold;
    font-size: $font-s;
    flex-shrink: 0;
    @include smartphone{
      width: 100%;
      margin-bottom: $space-xs;
    }
  }
  &-required{
    .label:after{
      content: "必須";
      font-size: $font-xs;
      background: $color-strong;
      padding: 3px 5px;
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
      font-weight: normal;
    }
  }
  &-text{
    font-size: $font-xs;
    margin-bottom: $space-m;
    line-height: 1.5;
    margin-left: 270px;
    @include smartphone{
      margin-left: 0;
    }
  }
  input, textarea, .select-wrapper{
    border: 0;
    outline: 0;
    padding: $space-xxs;
    background: $bg-gray;
    color: $color-black;
    line-height: 1.5;
    height: auto;
    font-size: $font-m;
    width: 100%;
  }
  input[type="submit"]{
    outline: 0;
    border: 0;
    display: block;
    cursor: pointer;
    font-size: $font-s;
    font-weight: bold;
    color: #fff;
    background: $color-black;
    padding: $space-s 100px;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    width: auto;
    margin: 30px auto 0;
    border-radius: 0;
    transition: all .3s ease-in-out;
    border: 1px solid $color-black;
    &:hover{
      transition: all .3s ease-in-out;
      background: rgba(0,0,0,0.2);
      color: $color-black;
    }
  }
  select{
    // reset;
    width: 100%;
    padding-right: 1em;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    padding: $space-xs $space-m $space-xs $space-xs;
    width: 100%;
    height: 100%;
    font-size: $font-s;
  }
  .select-wrapper{
    position: relative;
    padding: 0;
    &:after{
      content: '\f3d0';
      font-family: 'Ionicons';
      position: absolute;
      right: $space-xs;
      top: 6px;
    }
  }

  .textarea-wrapper{
    height: 400px;
  }
  .input-w100{
    width: 100%;
  }
}