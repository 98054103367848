// Breakpoints
$smartphone: 480px !default;
$tablet: 768px !default;
$laptop: 1024px !default;
$desktop: 1200px !default;

// spaces
$space-xxs: 6px;
$space-xs:10px;
$space-s: 20px;
$space-m: 30px;
$space-l: 40px;
$space-xl: 50px;
$space-xxl: 60px;
$space-xxxl: 80px;

$spaces:(
  0: 0,
  6: #{$space-xxs},
  10: #{$space-xs},
  20: #{$space-s},
  30: #{$space-m},
  40: #{$space-l},
  50: #{$space-xl},
  60: #{$space-xxl},
  80: #{$space-xxxl}
);

// fonts
$font-xxs: 10px;
$font-xs: 14px;
$font-s: 16px;
$font-ms: 18px;
$font-m: 20px;
$font-l: 24px;
$font-xl: 36px;
$font-xxl: 48px;

//color
$color-white: #FFF !default;
$color-black: #1A1A1A !default;
$color-gray: #F6F6F6 !default;
$color-lightgray: #E0E0E0 !default;
$color-darkgray: #848484 !default;
$color-primary: #085FD2 !default;
$color-secondary: #0FA0DC !default;
$color-strong: #a40707 !default;
$color-textgray: #555;

$colors:(
  white: #{$color-white},
  black: #{$color-black},
  primary: #{$color-primary},
  secondary: #{$color-secondary},
  textgray:  #{$color-textgray},
);

$bg-white: #FFF !default;
$bg-gray: #F6F6F6 !default;
$bg-darkgray: #848484 !default;
$bg-primary: #085FD2 !default;
$bg-secondary: #0FA0DC !default;
$bg-tertiary: #1A1A1A !default;

$bg-colors:(
  white: #{$bg-white},
  gray: #{$bg-gray},
  primary: #{$bg-primary},
  secondary: #{$bg-secondary},
  tertiary: #{$bg-tertiary}
);
