@use "stylesheets/foundation/variables" as *;

@each $name, $space in $spaces {
  .p{
    &-#{$name} {
      padding: $space;
    }
  }
  .py{
    &-#{$name} {
      padding: $space 0;
    }
  }
  .pr{
    &-#{$name} {
      padding-right: $space;
    }
  }
  .pl{
    &-#{$name} {
      padding-left: $space;
    }
  }
  .pt{
    &-#{$name} {
      padding-top: $space;
    }
  }
  .pb{
    &-#{$name} {
      padding-bottom: $space;
    }
  }

  .m{
    &-#{$name} {
      margin: $space;
    }
  }
  .my{
    &-#{$name} {
      margin: $space 0;
    }
  }
  .mt{
    &-#{$name} {
      margin-top: $space;
    }
  }
  .mb{
    &-#{$name} {
      margin-bottom: $space;
    }
  }
}