@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.button{
  padding: $space-s 100px;
  display: inline-block;
  text-decoration: none;
  color: $color-primary;
  font-weight: bold;
  position: relative;
  letter-spacing: .15rem;
  transition: all .3s ease-in-out;
  @include smartphone{
    padding: $space-s $space-xxl;
    font-size: $font-xs;
  }
    &-primary{
    background: $color-black;
    border: 1px solid $color-black;
    color: #fff;
  }
  &-secondary{
    background: #fff;
    border: 1px solid #fff;
    color: $color-black;
  }
  &:hover{
    transition: all .3s ease-in-out;
  }
  &-primary:hover,
  &-primary:focus{
    background: rgba(0,0,0,0.2);
    color: $color-black;
  }
  &-secondary:hover
  &-secondary:focus{
    background:  rgba(255,255,255,0.5);
    color: #fff;
  }

  &__wrapper{
    display: flex;
  }
  &--left{
    justify-content: flex-start;
    @include smartphone{
      justify-content: center;
    }
  }
  &--center{
    justify-content: center;
  }
  &--right{
    justify-content: flex-end;
    @include smartphone{
      justify-content: center;
    }
  }
}
