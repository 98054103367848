@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.header-lead.service{
  background-image: url(./images/service/lead.jpg);
}

// 一覧ページ
.container .service-item:not(:last-of-type) {
  margin-bottom: $space-m;
}
.service-item{
  &__left{
    padding: $space-l $space-m $space-m;
    @include tablet{
      padding: $space-m $space-s $space-s;
    }
  }
  &-heading{
    font-size: $font-l;
    font-weight: bold;
    margin-bottom: $space-s;
    @include smartphone{
      font-size: $font-m;
    }
  }
  &-text{
    font-size: $font-s;
    line-height: 1.5;
    @include smartphone{
      font-size: $font-xs;
    }
  }
  &-to{
    font-size: $font-l;
    font-family: 'Lato',sans-serif;
    text-decoration: underline;
    font-style: italic;
    font-weight: 700;
    margin-top: $space-l;
    margin-bottom: $space-xs;
    letter-spacing: .05em;
    @include smartphone{
      font-size: $font-m;
    }
  }
  &-list{
    li{
      padding-left: 25px;
      line-height: 1.5;
      margin-bottom: $space-xs;
      position: relative;
    }
    li:before{
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      top: 6px;
      border-left: 3px solid $color-black;
      border-bottom: 3px solid $color-black;
      width: 12px;
      height: 6px;
      transform: rotate(-50deg);
    }
  }
  .button{
    margin-top: $space-l;
    @include smartphone{
      margin-top: $space-s;
    }
  }
  &__right{
    // background: red;
    overflow: hidden;
    padding: 0;
    img{
      height: 100%;
      width: auto;
      @include smartphone{
        width: 100%;
        height: auto;
      }
    }
  }
}

// 個別ページ
.single-service .main {
  line-height: 1.7;
  .header-lead {
    height: auto;
    padding: $space-s 0 $space-m;
    font-weight: bold;
    line-height: 2;
    .page-label {
      font-size: $font-s;
      margin-bottom: $space-m;
    }
  }
  h2:not(.section-label) {
    font-weight: bold;
    font-size: $font-l;
    margin-bottom: $space-m;
    @include smartphone {
      font-size: $font-m;
    }
  }
  h3 {
    font-weight: bold;
    font-size: $font-m;
    margin-bottom: $space-s;
    @include smartphone {
      font-size: $font-s;
    }
  }
  p {
    @include smartphone {
      font-size: $font-xs;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  figcaption {
    font-weight: bold;
    font-size: $font-xs;
    color: $color-textgray;
    @include smartphone {
      font-size: $font-xxs;
    }
  }
  .service-section1,
  .service-section2 {
    line-height: 2;
  }
  .service-section2,
  .service-section3,
  .service-section4, {
    h2 {
      text-align: center;
    }
  }
  .service-section2,
  .service-section3 {
    h2 {
      @include smartphone {
        text-align: left;
      }
    }
  }

  .service-section1 {
    .button {
      @include smartphone {
        padding: 20px;
      }
    }
  }
  .service-section2 {
    figure {
      width: 80%;
      height: auto;
      margin: auto;
      @include smartphone {
        width: 100%;
      }
    }
  }
  .service-section3 {
    .row:not(:last-of-type) {
      margin-bottom: $space-l;
    }
    .row:nth-of-type(even) {
      flex-direction: row-reverse;
      @include smartphone {
        flex-direction: row;
      }
    }
  }
  .service-section4 {
    .service-step {
      border-bottom: 1px solid $color-darkgray;
      margin-bottom: $space-m;
      .row {
        align-items: center;
        flex-wrap: nowrap;
        padding: $space-s;
        border-top: 1px solid $color-darkgray;
      }
      &__number {
        flex-shrink: 0;
        flex-basis: 120px;
        color: $color-textgray;
        font-weight: bold;
        @include smartphone {
          flex-basis: 80px;
        }
        span {
          font-size: $font-l;
          padding: 0 $space-xxs;
        }
      }
      &__text {
        font-size: $font-xs;
      }
      &__heading {
        margin-bottom: $space-xxs;
        font-weight: bold;
        color: $color-textgray;
        font-size: $font-s;
      }
      &__after {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-xs;
        color: $color-textgray;
        @include smartphone {
          display: block;
        }
        &--logo {
          display: flex;
          align-items: center;
          justify-content: center;
          @include smartphone {
            margin-bottom: $space-s;
          }
        }
        img {
          width: 100px;
          margin-right: $space-xs;
        }
      }
    }
  }
  .form .select-wrapper,
  .form input:not([type=submit]),
  .form textarea {
    background: #fff;
  }
}
