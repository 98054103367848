@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.about{
  background-image: url(./images/about/lead.jpg);
}
.about-section{
  &4,&5{
    dl{
      display: flex;
      font-weight: 600;
      line-height: 2;
      margin-bottom: $space-xs;
      @include smartphone{
        display: block;
        margin-bottom: $space-m;
      }
    }
    dt{
      padding-right: $space-m;
      width: 200px;
      flex-shrink: 0;
      color: $color-textgray;
      @include smartphone{
        width: 100%;
        margin-bottom: $space-xs;
      }
    }
    dd{
      @include smartphone{
        font-weight: normal;
      }
    }
  }
}

.about-content{
  & div[class^='col-']{
    padding-top: $space-xs;
    padding-bottom: $space-xs;

    @include smartphone{
      padding-top: $space-xxs;
      padding-bottom: $space-xxs;
    }

    & > h2 {
      font-size: $font-ms;
      font-weight: bold;
      letter-spacing: 0.01rem;
      line-height: 1.5;
      position: relative;
      padding-left: $space-s;

      @include smartphone {
        font-size: $font-s;
      }

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        display: inline-block;
        background: linear-gradient(to bottom, $bg-secondary, $bg-primary);
        position: absolute;
        left: 0;
        top: 11px;

        @include smartphone {
          width: 8px;
          height: 8px;
          top: 9px;
        }
      }
    }
  }

  & .animatable{
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.3s ease;
  }

  & .animated{
    opacity: 1;
    transform: translateY(0px);
    transition: all 0.5s ease;
  }
}

#t-about-heading {
  height: 54px;
  visibility: hidden;
  @include smartphone{
    height: 28.5px;
  }
}

#t-about-message{
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.3s ease;
  &.animated {
    opacity: 1;
    transform: translateY(0px);
    transition: all 0.3s ease;
  }
}