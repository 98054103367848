@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.header{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: $bg-tertiary;
  transition: .5s;
  position: fixed;
  top: 0;
  z-index: 999;
  &__inner{
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    @include smartphone{
      height: 75px;
    }
  }
  &__logo{
    width: 75px;
    position: absolute;
    left: 0;
    z-index: 9999;
    @include smartphone{
      width: 45px;
      left: 15px;
    }
    img{
      width: 100%;
    }
  }
  &-nav{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include smartphone{
      padding: 3.6rem;
      // border: 5px solid $color-primary;
      transform: scale(1.1);
      opacity: 0;
      pointer-events: none;
      transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
      z-index: 1000;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
    .menu{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
      &-item{
        margin-right: $space-s;
        color: #fff;
        font-family: 'Lato', sans-serif;
        letter-spacing: .15rem;
        @include smartphone{
          width: 100%;
          margin: $space-s 0;
        }
        span{
          margin-top: $space-xxs;
          font-size: $font-xs;
          font-family: 'Noto Sans JP', sans-serif;
          display: block;
        }
        a{
          color: #fff;
          text-decoration: none;
        }
        &:last-of-type{
          margin-right: 0;
        }
      }
    }
  }
}


.home{
  .header{
    background-color:transparent;
    &.--fixed{
      background-color: $bg-tertiary;
      transition: .5s;
    }
  }
}

.nav-opened{
  .header__inner{
    .header-nav{
      background: $bg-tertiary;
      transform: scale(1);
      opacity: 1;
      pointer-events: auto;
      .menu{
        flex-wrap: wrap;
        transform: translate(0px,0);
      }
    }
  }
}

.admin-bar {
  .header{
    top: 32px;
    @include smartphone{
      // top: 46px;
      &-nav{
        // top: 46px;
      }
    }
  }
}

.noscroll{
  overflow: hidden;
}

.menu-trigger{
  width: 21px;
  height: 16px;
  vertical-align: middle;
  cursor: pointer;
  position: absolute;
  // bottom: 23px;
  right: 21px;
  z-index: 9999;
  display: none;
  @media only screen and ( max-width: $smartphone){
    display: inline-block;
  }
  span {
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all .5s;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 7px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
  &.active span {
    background-color: #fff;
    &:nth-of-type(1) {
      transform: translateY(7px) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(-7px) rotate(45deg);
    }
  }
}
