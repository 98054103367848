@charset "UTF-8";
/*
 * Theme Name: edridge
 * Description: This is our original theme.
 * Author: Orient Sailing Partners LLC.
 * Author URI: http://www.orient-sailing.com/
 * Version:1.0
 * */

@use "stylesheets/foundation/reset" as *;
@use "stylesheets/foundation/font" as *;
@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;
// @import "stylesheets/foundation/*";
@use "stylesheets/utilities/_colors.scss";
@use "stylesheets/utilities/_display.scss";
@use "stylesheets/utilities/_grid.scss";
@use "stylesheets/utilities/_space.scss";;
@use "stylesheets/layouts/_container.scss";
@use "stylesheets/layouts/_footer.scss";
@use "stylesheets/layouts/_header.scss";
@use "stylesheets/layouts/_section.scss";;
@use "stylesheets/pages/_about.scss";
@use "stylesheets/pages/_careers.scss";
@use "stylesheets/pages/_index.scss";
@use "stylesheets/pages/_news.scss";
@use "stylesheets/pages/_service.scss";
@use "stylesheets/pages/_single.scss";;
@use "stylesheets/components/_breadcrumb.scss";
@use "stylesheets/components/_contact.scss";
@use "stylesheets/components/_form.scss";
@use "stylesheets/components/_header-lead.scss";
@use "stylesheets/components/_loading.scss";
@use "stylesheets/components/_pagenavi.scss";;
@use "stylesheets/elements/_button.scss";
@use "stylesheets/elements/_typography.scss";;


$ionicons-font-path: "./fonts" !default;
@import './node_modules/ionicons/scss/ionicons.scss';
