@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.index-hero{
  width: 100%;
  height: 85vh;
  background-image: url(./images/index/hero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  position: relative;
  display: flex;
  justify-content: center;
  &-message{
    padding-right: $space-m;
    text-align: left;
    position: absolute;
    top: 40vh;
    color: #fff;
    width: 100%;
    & .main{
      margin-bottom: $space-xs;
      font-size: 40px;
      font-family: 'Lato', sans-serif;
      font-style: italic;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: .15rem;
      @include smartphone{
        font-size: 22px;
      }
    }
    & .sub{
      margin-bottom: $space-xs;
      font-size: 18px;
      font-weight: bold;
      line-height: 2;
      letter-spacing: .15rem;
      @include smartphone{
        font-size: 12px;
      }
    }
  }
  &:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0));
    background-size: 600% 600%;
    background-position:0% 50%;
    animation: hero-gradation 10s;
    opacity: 0;
    z-index: 1;
  }
}
@keyframes hero-gradation{
  0%{opacity: 0.9; background-position:0%}
  50%{opacity: 0.5; background-position:100% 50%}
  100%{background-position:0% 50%}
}

.index-about{
  &__heading{
    margin-bottom: $space-m;
    font-size: $font-xl;
    font-weight: bold;
  }
  &__body{
    line-height: 2;
  }
  &__img{
    padding: 0;
    img{
      display: block;
      width: 90%;
      height: auto;
      margin: 0 auto;
      @include smartphone{
        margin-top: $space-m;
      }
    }
  }
}

.index-service{
  background-image: url(./images/index/service.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  &__heading{
    font-size: $font-m;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;
    line-height: 1.8;
    @include smartphone{
      font-size: $font-s;
    }
  }
  &__section{
    & > h3{
      margin-bottom: $space-xs;
      padding: $space-m;
      font-size: $font-l;
      font-family: 'Lato', sans-serif;
      font-weight: bold;
      text-align: center;
      letter-spacing: .15rem;
      background: rgba(255, 255, 255, .7);
      span{
        margin-top: $space-xs;
        font-size: $font-s;
        font-weight: 100;
        display: block;
      }
    }
    & > p{
      color: #fff;
      line-height: 1.5;
    }
  }
}

.index-recruit{
  &-section{
    &__text{
      padding-right: $space-xxl;
      @include smartphone{
        padding-right: .75rem;
      }
    }
    &__thumbnail{
      position: relative;
      img{
        width: 100%;
        position: relative;
        z-index: 2;
      }
      &:after{
        width: 100%;
        height: 350px;
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        left: -30px;
        bottom: 0;
        background: $bg-gray;
        @include smartphone{
          height: 200px;
        }
      }
    }
  }
}
