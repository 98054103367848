@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.news{
  &-title{
    font-size: $font-l;
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
    @include tablet{
      font-size: $font-s;
      text-align: left;
    }
  }
  &-heading{
    padding-bottom: $space-m;
    margin-bottom: $space-m;
    border-bottom: 1px solid $color-darkgray;
  }
  &-info{
    text-align: right;
    font-size: $font-xs;
    line-height: 2;
  }
  &-cat-wrapper{
    display: flex;
    margin-bottom: $space-s;
  }
  &-cat{
    width: 100px;
    text-align: center;
    display: inline-block;
    background: $bg-darkgray;
    padding: $space-xxs;
    color: #fff;
    font-size: $font-xxs;
    margin: $space-xxs;
  }
}

.news-item{
  display: flex;
  align-items: center;
  @include tablet{
    flex-wrap: wrap;
  }
  &__cat-wrapper,
  &__date{
    margin-right: $space-xs;
  }
  &__date{
    font-weight: bold;
    font-size: $font-xs;
    @include tablet{
      margin-bottom: $space-xxs;
    }
  }
  &__title{
    color: $color-black;
    line-height: 1.5;
    &:hover{
      color: $color-secondary;
    }
    @include tablet{
      width: 100%;
    }
  }
}

.contact-news{
  .col-6:first-of-type{
    border-right: 2px solid $bg-gray;
    @include tablet{
      border-right: 0;
      border-bottom: 2px solid $bg-gray;
    }
  }
  &__text {
    line-height: 2;
    font-size: $font-xs;
    @include tablet{
      font-size: $font-xs;
    }
  }
}
