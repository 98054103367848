@use "stylesheets/foundation/variables" as *;
@use "sass:math";

@mixin col($nam) {
  width: math.div(100% * $nam, 12);
  padding: .75rem;
  @media screen and (max-width: $smartphone){
    width: 100%;
  }
}

@for $nam from 1 through 12{
  .col-#{$nam}{
    @include col($nam);
  }
}

@media screen and (max-width: $tablet){
  @for $nam from 1 through 12{
    .col-sp-#{$nam}{
      @include col($nam);
    }
  }
}

.row{
  display: flex;
  flex-wrap: wrap;
  margin-left: -.75rem;
  margin-right: -.75rem;
}