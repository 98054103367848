@use "stylesheets/foundation/mixins" as *;

.d-pc-none{
  display: none;
  @include smartphone{
    display: block;
  }
}
.d-sp-none{
  display: block;
  @include smartphone{
    display: none;
  }
}