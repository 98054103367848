@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.grecaptcha-badge{
  display: none;
}

.footer{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &-offices{
    margin-bottom: $space-m;
    display: flex;
    &__spot{
      & > h4{
        margin-bottom: $space-xs;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
      }
      & > p{
        line-height: 1.5;
        color: $color-textgray;
        @include smartphone{
          font-size: $font-xs;
        }
      }
      &:first-of-type{
        margin-right: $space-xxxl;
        @include smartphone{
          margin-right: 0;
          margin-bottom: $space-m;
        }
      }
    }
    @include smartphone{
      display: block;
    }
  }
  &-lead{
    display: flex;
    justify-content: space-between;
    @include smartphone{
      flex-direction: column-reverse;
    }
    &__logo{
      display: flex;
      align-items: center;
      @include smartphone{
        margin-top: $space-s;
      }
      img{
        width: 100px;
        @include smartphone{
          width: 75px;
        }
      }
      p{
        margin-left: $space-m;
        font-family: 'Lato', sans-serif;
        letter-spacing: .1rem;
        @include smartphone{
          margin-left: $space-xs;
          font-size: 8px;
          letter-spacing: .05rem;
        }
      }

    }
    &-nav .menu-footer-container{
      display: flex;
      align-items: center;
      .menu{
        display: flex;
        @include smartphone{
          flex-wrap: wrap;
        }
        &-item{
          margin-right: $space-m;
          @include smartphone{
            width: 100%;
            margin-bottom: $space-xxs;
          }
          a{
            font-weight: bold;
            color: $color-black;
            text-decoration: none;
            @include smartphone{
              font-size: $font-xs;
            }
          }
          &:last-of-type{
            margin-right: 0;
          }
        }
      }
    }
  }
}