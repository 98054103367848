@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

:root{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: $font-s;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

.section-label{
  margin-bottom: $space-m;
  font-size: $font-s;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-align: left;
  letter-spacing: .1rem;
  span{
    margin-bottom: $space-xs;
    font-size: $font-m;
    display: block;
  }
  &--primary{
    color: $color-darkgray;
    span{
      background: linear-gradient(to bottom, $bg-secondary, $bg-primary);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: $color-primary;
    }
  }
  &--secondary{
    color: #fff;
    span{
      color: #fff;
    }
  }
}

.page-label{
  font-size: $font-m;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-align: left;
  letter-spacing: .1rem;
  @include smartphone{
    font-size: $font-s;
  }
  span{
    margin-bottom: $space-xs;
    font-size: $font-l;
    display: block;
    @include smartphone{
      font-size: $font-s;
    }
    &:after{
      margin-top: $space-s;
      margin-bottom: $space-s;
      content: "";
      display: block;
      width: 50px;
      height: 2px;
      background: #fff;
      @include smartphone{
        margin-top: $space-xs;
        margin-bottom: $space-xs;
      }
    }
  }
  &--primary{
    color: $color-darkgray;
    span{
      background: linear-gradient(to bottom, $bg-secondary, $bg-primary);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: $color-primary;
    }
  }
  &--secondary{
    color: #fff;
    span{
      color: #fff;
    }
  }
}

.header-lead{
  color: #fff;
}

.heading{
  margin-bottom: $space-m;
  font-size: $font-xl;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  line-height: 1.5;
  @include smartphone{
    margin-bottom: $space-s;
    font-size: $font-l;
    line-height: 1.2;
  }
}
.subheading{
  font-size: $font-m;
  font-weight: bold;
  margin-top: $space-m;
  @include smartphone{
    font-size: $font-s;
  }
}

.message{
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
  color: $color-textgray;
  @include smartphone{
    font-size: 14px;
  }
  &.--strong{
    font-weight: bold;
  }
}

.text-m{
  font-size: $font-m;
  line-height: 1.5;
}

.text-s{
  font-size: $font-s;
  line-height: 1.5;
}

.underline{
  text-decoration: underline;
}

.bold{
  font-weight: bold;
}

a{
  text-decoration: none;
  color: $color-secondary;
  transition: all .3s ease-in-out;
  &:hover{
    color: $color-black;
  }
}