@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.wp-pagenavi{
  margin-top: $space-l;
  text-align: center;
  a, span{
    padding: 10px;
    margin-right: 1px;
    font-weight: bold;
    display: inline-block;
    line-height: 20px;
  }
  a{
    transition: all .3s ease-in-out;
    background: $bg-white;
    &:hover{
      background: $bg-secondary;
      color: #fff;
    }
  }

  .pages{
    font-size: $font-xs;
    font-weight: bold;
  }
  .page{
    background: $bg-white;
  }
  .current{
    background: $bg-darkgray;
    color: $bg-white;
  }
}