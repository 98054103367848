@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/mixins" as *;

.header-lead.careers{
  background-image: url(./images/careers/lead.jpg);
}
.archive .careers{
    //---------- archive-careers ----------
  &-item{
    background: $color-white;
    margin-bottom: $space-s;
    font-size: $font-m;
    width: 49%;
    @include smartphone{
      width: 100%;
      font-size: $font-s;
    }
    a{
      text-decoration: none;
      font-size: $font-s;
      color: $color-black;
      display: flex;
      width: 100%;
      height: 100%;
      padding: $space-m $space-s;
      align-items: center;
      justify-content: space-between;
      line-height: 1.5;
      &:after{
        font-family: "Ionicons";
        content: "\f3d3";
        padding-left: $space-s;
      }
      &:hover{
        transition: all .3s ease-in-out;
        background: $color-black;
        color: #fff;

      }
    }
    &-wrapper{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}

//----------  single-careers  ----------
.single .careers{
  &-cat{
    font-size: $font-m;
    margin-bottom: $space-l;
    @include smartphone{
      font-size: $font-s;
      margin-bottom: $space-m;
    }
  }

  &-heading{
    font-size: $font-l;
    font-weight: bold;
    margin-top: $space-l;
    line-height: 1.5;
    @include smartphone{
      font-size: $font-s;
    }
  }
  &-table{
    background: white;
    padding: $space-l $space-m;
    margin-bottom: $space-l;
    @include smartphone{
      padding: $space-m $space-s;
    }
    &-heading{
      font-size: $font-l;
      font-weight: bold;
      margin-bottom: $space-m;
      @include smartphone{
        font-size: $font-s;
      }
    }
    dl{
      border-top: 1px solid $color-lightgray;
      display: flex;
      padding: $space-s 0;
      line-height: 1.5;
      @include smartphone{
        display: block;
        font-size: $font-xs;
      }
    }
    dt{
      width: 250px;
      flex-shrink: 0;
      text-align: left;
      font-weight: bold;
      @include smartphone{
        width: 100%;
        margin-bottom: $space-s;
        font-size: $font-s;
      }
    }
  }
  &-image{
    width: 100%;
    height: auto;
  }
  &-image-sub{
    margin-bottom: $space-m;
    img{
      width: 100%;
      height: auto;
    }
  }

}
