@charset "UTF-8";

@font-face {
  font-family: 'Ionicons';
  src: url('../fonts/ionicons.eot');
  src:
    url('../fonts/ionicons.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ionicons.woff') format('woff'),
    url('../fonts/ionicons.ttf') format('truetype'),
    url('../fonts/ionicons.svg#Ionicons') format('svg');
  font-style: normal;
  font-weight: normal;
}

i[class^="ion-"]{
  display: inline-block;
  font-family: "Ionicons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


i[class^="ion-"]:before{
  font-family: "Ionicons";
}

/* example */
.ion-ios-arrow-up:before { content: "\f3d8"; }
.ion-ios-arrow-left:before { content: "\f3d2"; }
.ion-ios-arrow-right:before { content: "\f3d3"; }
.ion-ios-arrow-down:before { content: "\f3d0"; }
.ion-ios-telephone:before { content: "\f4b9"; }
.ion-alert-circled:before { content: "\f100"; }
.ion-ios-email:before { content: "\f423"; }
.ion-plus:before { content: "\f218"; }

.ion-android-arrow-dropup:before { content: "\f365"; }
.ion-android-arrow-dropright:before { content: "\f363"; }
.ion-android-arrow-dropdown:before { content: "\f35f"; }
.ion-android-arrow-dropleft:before { content: "\f361"; }

.ion-android-arrow-dropright-circle:before { content: "\f362"; }